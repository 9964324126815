import { Theme } from "theme-ui"

export const ColorGray200 = "hsl(0, 0%, 94%)"
const ColorGray300 = "hsl(0, 0%, 60%)"
const ColorGray900 = "#292b29"
const ColorGray900Alpha03 = "hsla(0, 0%, 12%, 0.03)"
export const ColorGray900Alpha15 = "hsla(0, 0%, 12%, 0.15)"
const ColorGray900Alpha40 = "hsla(0, 0%, 12%, 0.4)"
const ColorGray900Alpha60 = "hsla(0, 0%, 12%, 0.6)"
const ColorGray900Alpha80 = "hsla(0, 0%, 12%, 0.8)"
const ColorWhite = "rgb(255 255 255 / 1)"
const ColorWhiteAlpha10 = "hsla(0, 0%, 100%, 0.1)"
const ColorWhiteAlpha40 = "rgb(255 255 255 / 0.4)"
export const ColorGreen300 = "#004733"
const ColorGreen500 = "#4b815c"
const ColorBlue100 = "hsl(215, 100%, 97%)"
const ColorBlue400 = "hsl(210, 100%, 47%)"
const BetterOffWhite = "#fffdfa"
const BetterAccentGreen = "#1ee07f"
export const BetterAccentOrange = "#f8a530"

export const themeTokens: Theme = {
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
    },
  },

  colors: {
    text: ColorGray900,
    textMuted: ColorGray900,
    textMutedMore: ColorGray900Alpha60,
    textWhite: ColorWhite,
    textWhiteMuted: ColorWhiteAlpha40,
    textLink: ColorGreen300,
    textAccent: ColorGreen500,
    background: BetterOffWhite,
    backgroundPrimary: ColorGreen300,
    backgroundSecondary: ColorGray900,
    backgroundTertiary: ColorGray200,
    backgroundSelection: BetterAccentGreen,
    backgroundSelectionDark: ColorGray900Alpha15,
    backgroundSelectionWhite: ColorWhiteAlpha10,
    backgroundRow: ColorGray900Alpha03,
    divider: ColorWhite,
    border: ColorGray300,
  },

  fonts: {
    body: "Better Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    heading: "Better Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    monospace: "Menlo, monospace",
  },

  fontWeights: {
    body: 400,
    heading: 500,
    bold: 600,
  },

  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },

  letterSpacings: {
    body: "normal",
    caps: "0.2em",
    large1: "0.03em",
    large2: "0.1em",
    large3: "0.15em",
    tight1: "-0.005em",
    tight2: "-0.01em",
    tight3: "-0.025em",
    tight4: "-0.035em",
    tight5: "-0.045em",
    tight6: "-0.060em",
  },

  text: {
    default: {
      variant: "text.selection",
      fontFamily: "body",
      color: "text",
      lineHeight: 1.75,
      letterSpacing: "tight3",
      wordSpacing: "0.03em",

      strong: {
        fontWeight: 500,
      },

      em: {
        fontStyle: "italic",
      },
    },

    body: {
      variant: "text.default",
      fontSize: [4, 5, 4, 6, null, 7],
      mb: [3, 4, 3, 5, 6],
    },

    blockquote: {
      variant: "text.default",
      fontWeight: 500,
      fontSize: [5, 6, 5, 7, null, 8],
      textAlign: "center",
      fontStyle: "italic",
    },

    small: {
      variant: "text.default",
      color: "textMuted",
      fontSize: [2, 3, 4],
    },

    code: {
      fontSize: [2, 3, 2, 4, null, 5],
      borderRadius: "small",
      border: 0,
      bg: ColorBlue100,
      color: ColorBlue400,
      display: "inline-grid",
      fontWeight: 400,
      fontFamily: "monospace",
      lineHeight: 1.4,
      py: 1,
      px: "6px",
      position: "relative",
      whiteSpace: "nowrap",
      wordBreak: "break-all",
      wordWrap: "break-word",
      mb: [1],
    },

    td: {
      fontSize: [2, 3, 2, 4, null, 5],
    },

    th: {
      fontSize: [2, 3, 2, 4, null, 5],
    },

    heading: {
      variant: "text.selection",
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      letterSpacing: "tight4",
      wordSpacing: "0.03em",
    },

    h1: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [9, 10, 9, 11, 12],
      letterSpacing: ["tight3", null, "tight4"],
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    h2: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [8, 9, 8, 10, 11],
      letterSpacing: ["tight3", null, "tight4"],
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    h3: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [7, 8, 7, 9, 10],
      letterSpacing: ["tight3", null, "tight4"],
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    h4: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [6, 7, 6, 8, 9],
      letterSpacing: "tight3",
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    h5: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [5, 6, 5, 7, 8],
      letterSpacing: "tight4",
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    h6: {
      variant: "text.selection",
      wordSpacing: "0.01em",
      fontSize: [4, 5, 4, 6, 7],
      letterSpacing: "tight4",
      "& + p": {
        mt: [4, 5, 6, 7],
      },
    },

    selection: {
      "::selection": {
        bg: "backgroundSelection",
      },
      "span, strong, em, div": {
        "::selection": {
          bg: "backgroundSelection",
        },
      },
    },

    a: {
      color: "primary",
      ":hover, :focus": {
        color: "secondary",
      },
    },

    orderedList: {
      listStyle: "decimal",
      counterReset: "li",
      pl: [4, 5],
      li: {
        variant: "text.default",
        fontSize: [4, 5, 4, 6, null, 7],
        mb: [3, 4, 3, 5, 6],
        counterIncrement: "li",
        "::marker": {
          content: `counter(li) "  "`,
          color: "textAccent",
          width: 10,
          fontSize: [2, 3, 2, 3, 4],
        },
      },
    },

    unorderedList: {
      listStyle: "disc",
      counterReset: "li",
      pl: [4, 5],
      li: {
        variant: "text.default",
        fontSize: [4, 5, 4, 6, null, 7],
        mb: [3, 4, 3, 5, 6],
        counterIncrement: "li",
        "::marker": {
          color: "textAccent",
          width: 10,
        },
      },
    },

    ladderEyebrow: {
      variant: "text.h6",
      fontWeight: 500,
    },

    footerBody: {
      variant: "text.default",
      fontSize: [3, 4, 3, null, 5],
      color: "textWhiteMuted",
    },
  },

  space: [
    0, // 0
    4, // 1
    8, // 2
    12, // 3
    16, // 4
    20, // 5
    24, // 6
    32, // 7
    40, // 8
    48, // 9
    56, // 10
    64, // 11
    80, // 12
    96, // 13
    128, // 14
    192, // 15
    256, // 16
    512, // 17
  ],

  fontSizes: [
    9.989, // 0
    11.237, // 1
    12.642, // 2
    14.222, // 3
    16, // 4
    18, // 5
    20.25, // 6
    22.781, // 7
    25.629, // 8
    28.833, // 9
    32.437, // 10
    36.491, // 11
    41.053, // 12
    46.184, // 13
    51.957, // 14
    58.452, // 15
    65.758, // 16
    73.978, // 17
    83.225, // 18
    93.628, // 19
    105.332, // 20
    118.498, // 21
  ],

  layout: {
    container: {
      px: ["5%", null, null, null, "10%"],
    },

    pipe: {
      gridColumn: ["1/13", "2/12", "3/11", "4/10"],
    },

    listDecimal: {
      listStyle: "decimal",
      counterReset: "li",
      pl: [4, 5],
      li: {
        counterIncrement: "li",
        mb: [3, 4, 3, 5, 6],
        "::marker": {
          content: `counter(li) "  "`,
          color: "textAccent",
          width: 10,
          fontSize: [2, 3, 2, 3, 4],
        },
      },
    },

    listDisc: {
      listStyle: "disc",
      counterReset: "li",
      pl: [4, 5],
      li: {
        counterIncrement: "li",
        mb: [2, 3, 2, 4],
        "::marker": {
          color: "textAccent",
          width: 10,
        },
      },
    },

    ladderList: {
      my: [7, 8, 7, 8, 9],
      li: {
        mb: [7, 8, 7, 8, 11],
        ":last-child": {
          mb: 0,
        },
      },
    },

    ladderListInner: {
      variant: "layout.listDisc",
      my: [3, 4, 3, null, 4, 5],
      li: {
        p: {
          mb: [3, 4, 3, null, null, 4],
        },
        mb: [3, 4, 3, null, null, 4],
        "::marker": {
          color: "textAccent",
          width: 10,
        },
      },
    },

    focusVisible: {
      ":focus": {
        outline: "none",
      },

      ":focus-visible": {
        outline: "1px dashed",
        outlineColor: "text",
      },
    },
  },

  grids: {
    base: {
      gridTemplateColumns: "repeat(12, 1fr)",
      columnGap: [2, 6, null, 8, 10, 11],
      rowGap: 0,
    },
  },

  breakpoints: ["750px", "970px", "1170px", "1600px", "1920px"],

  zIndices: {
    navbar: 2,
    grid: 100,
  },

  radii: {
    small: "3px",
    medium: "6px",
  },

  badges: {
    primary: {
      fontFamily: "body",
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: "tight2",
      bg: "backgroundTertiary",
      color: "text",
      display: "inline-block",
      py: [1],
      px: [1, null, null, 2],
      fontSize: [1, null, null, 2],
      borderRadius: "small",
    },
    primaryDark: {
      variant: "badges.primary",
      filter: "darkness(10%)",
    },
  },

  shadows: {
    heroAbout: `0 8px 16px 0 ${ColorGray900Alpha15}`,
    heroJobs: `inset 0 0 0 6px ${ColorGray900Alpha15}`,
  },
}

export default themeTokens
